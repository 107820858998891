import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`29th March 2019`}</p>


    <h2 {...{
      "id": "new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#new-features",
        "aria-label": "new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now configure TLS for Armeria server when using `}<inlineCode parentName="p">{`armeria-spring-boot-autoconfigure`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1663"
          }}>{`#1663`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-yaml"
          }}>{`# Your application.yml

armeria:
   ports:
     - port: 8080
       protocols: HTTP
     - ip: 127.0.0.1
       port: 8081
       protocols: HTTP
     - port: 8443
       protocols: HTTPS
   ssl:
     key-alias: "host.name.com"
     key-store: "keystore.jks"
     key-store-password: "changeme"
     trust-store: "truststore.jks"
     trust-store-password: "changeme"
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Now, you have enough information to determine the status of a Thrift endpoint with `}<inlineCode parentName="p">{`InvalidResponseHeadersException`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1668"
          }}>{`#1668`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`EventLoopThread`}</inlineCode>{` now implements `}<inlineCode parentName="p">{`NonBlocking`}</inlineCode>{` tag interface using in Project Reactor. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1665"
          }}>{`#1665`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`Schedulers.isInNonBlockingThread()`}</inlineCode>{` returns `}<inlineCode parentName="li">{`true`}</inlineCode>{` when you call in the Armeria `}<inlineCode parentName="li">{`EventLoop`}</inlineCode>{`s.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "improvement",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#improvement",
        "aria-label": "improvement permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Improvement`}</h2>
    <ul>
      <li parentName="ul">{`Various documentation updates. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1666"
        }}>{`#1666`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1671"
        }}>{`#1671`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1677"
        }}>{`#1677`}</a></li>
    </ul>
    <h2 {...{
      "id": "bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#bug-fixes",
        "aria-label": "bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`The request body form is now shown when the HTTP method is POST or PUT in `}<inlineCode parentName="li">{`DocService`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1667"
        }}>{`#1667`}</a></li>
      <li parentName="ul">{`You can now use the `}<inlineCode parentName="li">{`HttpHeaderNames.PREFER`}</inlineCode>{` again. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1669"
        }}>{`#1669`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`IllegalReferenceCountException`}</inlineCode>{` is no longer raised when you enable content previews on the client-side. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1690"
        }}>{`#1690`}</a></li>
      <li parentName="ul">{`The request is no longer sent automatically but submitted only by clicking the submit button in `}<inlineCode parentName="li">{`DocService`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1689"
        }}>{`#1689`}</a></li>
    </ul>
    <h2 {...{
      "id": "breaking-change",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#breaking-change",
        "aria-label": "breaking change permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Breaking Change`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`doStart()`}</inlineCode>{` and `}<inlineCode parentName="li">{`doStop()`}</inlineCode>{` methods in `}<inlineCode parentName="li">{`StartStopSupport`}</inlineCode>{` now take a parameter which might be used as a startup and shutdown argument.`}
        <ul parentName="li">
          <li parentName="ul">{`Listener notification methods take a parameter as well. `}<a parentName="li" {...{
              "href": "https://github.com/line/armeria/issues/1673"
            }}>{`#1673`}</a>{` `}<a parentName="li" {...{
              "href": "https://github.com/line/armeria/issues/1675"
            }}>{`#1675`}</a></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dependencies",
        "aria-label": "dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Netty TCNative BoringSSL 2.0.22.Final -> 2.0.23.Final`}</li>
      <li parentName="ul">{`Project Reactor 3.2.6 -> 3.2.8`}</li>
      <li parentName="ul">{`RxJava 2.2.7 -> 2.2.8`}</li>
      <li parentName="ul">{`Tomcat 9.0.16 -> 9.0.17, 8.5.38 -> 8.5.39`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      